import { shallowEqual } from "shallow-equal-object";
import type { z } from "zod";

import type { Brand } from "./brand.ts";

interface ValueObjectProps {
  [index: string]: any;
}

/**
 * @desc ValueObjects are objects that we determine their
 * equality through their structrual property.
 */

export abstract class ValueObject<
  Props extends
    | Brand<ValueObjectProps, string>
    | (ValueObjectProps & z.BRAND<string>),
> {
  protected readonly props: Props;

  protected constructor(props: Props) {
    this.props = Object.freeze(props);
  }

  public equals(valueObject?: ValueObject<Props>): boolean {
    if (valueObject === null || valueObject === undefined) {
      return false;
    }
    if (valueObject.props === undefined) {
      return false;
    }
    return shallowEqual(this.props, valueObject.props);
  }
}
